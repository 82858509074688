import * as React from "react"
const PdfIcon = ({ fill = "#DB4726", width = 25, height = 25, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 20"
    width={width}
    height={height}
    fill="none"
    {...props}
  >
    <path
      fill={fill}
      d="M18.5 0h-12c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2ZM10 7.5C10 8.33 9.33 9 8.5 9h-1v1.25c0 .41-.34.75-.75.75S6 10.66 6 10.25V6c0-.55.45-1 1-1h1.5c.83 0 1.5.67 1.5 1.5v1Zm5 2c0 .83-.67 1.5-1.5 1.5h-2c-.28 0-.5-.22-.5-.5v-5c0-.28.22-.5.5-.5h2c.83 0 1.5.67 1.5 1.5v3Zm4-3.75c0 .41-.34.75-.75.75h-.75v1h.75c.41 0 .75.34.75.75s-.34.75-.75.75h-.75v1.25c0 .41-.34.75-.75.75s-.75-.34-.75-.75V6c0-.55.45-1 1-1h1.25c.41 0 .75.34.75.75ZM7.5 7.5h1v-1h-1v1ZM1.5 4c-.55 0-1 .45-1 1v13c0 1.1.9 2 2 2h13c.55 0 1-.45 1-1s-.45-1-1-1h-12c-.55 0-1-.45-1-1V5c0-.55-.45-1-1-1Zm11 5.5h1v-3h-1v3Z"
    />
  </svg>
)
export default PdfIcon