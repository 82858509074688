import React from 'react'


const SearchIcon = () => {

  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.9996 14.0006H15.2096L14.9296 13.7306C16.1296 12.3306 16.7496 10.4206 16.4096 8.39063C15.9396 5.61063 13.6196 3.39063 10.8196 3.05063C6.58965 2.53063 3.02965 6.09063 3.54965 10.3206C3.88965 13.1206 6.10965 15.4406 8.88965 15.9106C10.9196 16.2506 12.8296 15.6306 14.2296 14.4306L14.4996 14.7106V15.5006L18.7496 19.7506C19.1596 20.1606 19.8296 20.1606 20.2396 19.7506C20.6496 19.3406 20.6496 18.6706 20.2396 18.2606L15.9996 14.0006ZM9.99965 14.0006C7.50965 14.0006 5.49965 11.9906 5.49965 9.50063C5.49965 7.01063 7.50965 5.00063 9.99965 5.00063C12.4896 5.00063 14.4996 7.01063 14.4996 9.50063C14.4996 11.9906 12.4896 14.0006 9.99965 14.0006Z" fill="#ABAFB6"/>
    </svg>
  );
}


export default SearchIcon;