const initialState = {
   
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case 'GET-SOEPS':
        return state;
      
      default:
        return state;
    }
  };
  