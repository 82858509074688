import React from 'react';


const TrashIcon = () => {

  return(
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.58579 3.08579C8.96086 2.71071 9.46957 2.5 10 2.5H14C14.5304 2.5 15.0391 2.71071 15.4142 3.08579C15.7893 3.46086 16 3.96957 16 4.5V6.5H19H20C20.5523 6.5 21 6.94772 21 7.5C21 8.05228 20.5523 8.5 20 8.5H19.9311L19.1305 19.713C19.1305 19.7131 19.1305 19.7132 19.1305 19.7132C19.0765 20.47 18.7379 21.1783 18.1826 21.6954C17.6274 22.2125 16.8968 22.5 16.138 22.5H7.86202C7.10323 22.5 6.37262 22.2125 5.81735 21.6954C5.26213 21.1783 4.92347 20.47 4.86954 19.7132C4.86953 19.7132 4.86953 19.7131 4.86952 19.713L4.06886 8.5H4C3.44772 8.5 3 8.05228 3 7.5C3 6.94772 3.44772 6.5 4 6.5H5H8V4.5C8 3.96957 8.21071 3.46086 8.58579 3.08579ZM10 6.5H14V4.5H10V6.5ZM9 8.5H6.07395L6.86446 19.5708L6.86448 19.571C6.88243 19.8233 6.99533 20.0594 7.18042 20.2318C7.36551 20.4042 7.60905 20.5 7.862 20.5H16.138C16.391 20.5 16.6345 20.4042 16.8196 20.2318C17.0047 20.0594 17.1176 19.8233 17.1355 19.571L17.1355 19.5708L17.926 8.5H15H9ZM10 10.5C10.5523 10.5 11 10.9477 11 11.5V17.5C11 18.0523 10.5523 18.5 10 18.5C9.44772 18.5 9 18.0523 9 17.5V11.5C9 10.9477 9.44772 10.5 10 10.5ZM14 10.5C14.5523 10.5 15 10.9477 15 11.5V17.5C15 18.0523 14.5523 18.5 14 18.5C13.4477 18.5 13 18.0523 13 17.5V11.5C13 10.9477 13.4477 10.5 14 10.5Z" fill="black"/>
    </svg>
  );
};


export default TrashIcon;