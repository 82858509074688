import React from 'react';


const AttachmentIcon = () => {

  return(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.1725 6.99968L8.58651 13.5857C8.39549 13.7702 8.24312 13.9909 8.13831 14.2349C8.03349 14.4789 7.97831 14.7413 7.97601 15.0069C7.9737 15.2724 8.0243 15.5358 8.12486 15.7816C8.22543 16.0274 8.37393 16.2507 8.56172 16.4385C8.7495 16.6263 8.97281 16.7748 9.2186 16.8753C9.46439 16.9759 9.72775 17.0265 9.99331 17.0242C10.2589 17.0219 10.5213 16.9667 10.7653 16.8619C11.0093 16.7571 11.23 16.6047 11.4145 16.4137L17.8285 9.82768C18.5571 9.07327 18.9603 8.06286 18.9512 7.01407C18.9421 5.96528 18.5214 4.96203 17.7798 4.2204C17.0381 3.47877 16.0349 3.05809 14.9861 3.04898C13.9373 3.03987 12.9269 3.44304 12.1725 4.17168L5.75751 10.7567C4.63219 11.882 4 13.4082 4 14.9997C4 16.5911 4.63219 18.1174 5.75751 19.2427C6.88282 20.368 8.40907 21.0002 10.0005 21.0002C11.5919 21.0002 13.1182 20.368 14.2435 19.2427L20.5005 12.9997" stroke="#364152" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}


export default AttachmentIcon;