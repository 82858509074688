import React from 'react';


const NextPage = () => {

  return(
    <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.463867" y="0.253906" width="24.5363" height="22.4916" rx="4.08939" fill="#27BEC2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.3908 7.55988C13.5904 7.36025 13.9141 7.36025 14.1137 7.55988L17.6919 11.1381C17.8915 11.3377 17.8915 11.6614 17.6919 11.861L14.1137 15.4392C13.9141 15.6388 13.5904 15.6388 13.3908 15.4392C13.1912 15.2396 13.1912 14.9159 13.3908 14.7163L16.0964 12.0107H8.12934C7.84702 12.0107 7.61816 11.7819 7.61816 11.4995C7.61816 11.2172 7.84702 10.9884 8.12934 10.9884H16.0964L13.3908 8.28278C13.1912 8.08316 13.1912 7.7595 13.3908 7.55988Z" fill="white"/>
    </svg>
  );
}


export default NextPage;