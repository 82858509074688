import React from 'react';


const ImageIcon = () => {
  return(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H18C18.55 5 19 5.45 19 6V18C19 18.55 18.55 19 18 19ZM13.56 12.81L11.21 15.83L9.65 13.95C9.45 13.7 9.07 13.71 8.87 13.96L7.13 16.19C6.87 16.52 7.11 17 7.52 17H16.5C16.91 17 17.15 16.53 16.9 16.2L14.35 12.81C14.16 12.55 13.76 12.55 13.56 12.81Z" fill="#364F6B"/>
    </svg>
  );
}


export default ImageIcon;