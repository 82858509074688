import * as React from "react"

const FamilyIcon = ({fill="none", ...props}) => (
  <svg
    width={22}
    height={22}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.75 20.625h-2.063a1.377 1.377 0 0 1-1.374-1.375v-3.438h1.374v3.438h2.063v-3.438h1.375v-2.75a.688.688 0 0 0-.688-.687H8.442L7.067 8.25H2.75a.688.688 0 0 0-.688.688v4.124h1.376v6.188h2.75v-4.813h1.375v4.813a1.377 1.377 0 0 1-1.375 1.375h-2.75a1.377 1.377 0 0 1-1.376-1.375v-4.813a1.377 1.377 0 0 1-1.374-1.374V8.936A2.065 2.065 0 0 1 2.75 6.876h4.317a1.374 1.374 0 0 1 1.305.94L9.433 11h5.005a2.064 2.064 0 0 1 2.062 2.063v2.75a1.377 1.377 0 0 1-1.375 1.374v2.063a1.377 1.377 0 0 1-1.375 1.375ZM19.25 20.625h-1.375v-7.563h2.063V8.939a.688.688 0 0 0-.688-.688H16.5V6.875h2.75a2.065 2.065 0 0 1 2.063 2.063v4.124a1.376 1.376 0 0 1-1.375 1.376h-.688v6.187ZM4.813 6.188a2.75 2.75 0 1 1 0-5.5 2.75 2.75 0 0 1 0 5.5Zm0-4.125a1.375 1.375 0 1 0 0 2.75 1.375 1.375 0 0 0 0-2.75ZM17.188 6.188a2.75 2.75 0 1 1 0-5.5 2.75 2.75 0 0 1 0 5.5Zm0-4.125a1.375 1.375 0 1 0 0 2.75 1.375 1.375 0 0 0 0-2.75Z"
      fill={fill}
    />
    <path
      d="M12.719 10.313a2.406 2.406 0 1 1 0-4.812 2.406 2.406 0 0 1 0 4.811Zm0-3.438a1.031 1.031 0 1 0 0 2.063 1.031 1.031 0 0 0 0-2.063Z"
      fill={fill}
    />
  </svg>
)

export default FamilyIcon
