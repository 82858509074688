import React from 'react';


const FilterIcon = () => {

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.16667 15H10.8333C11.2917 15 11.6667 14.625 11.6667 14.1667C11.6667 13.7083 11.2917 13.3333 10.8333 13.3333H9.16667C8.70833 13.3333 8.33333 13.7083 8.33333 14.1667C8.33333 14.625 8.70833 15 9.16667 15ZM2.5 5.83333C2.5 6.29167 2.875 6.66667 3.33333 6.66667H16.6667C17.125 6.66667 17.5 6.29167 17.5 5.83333C17.5 5.375 17.125 5 16.6667 5H3.33333C2.875 5 2.5 5.375 2.5 5.83333ZM5.83333 10.8333H14.1667C14.625 10.8333 15 10.4583 15 10C15 9.54167 14.625 9.16667 14.1667 9.16667H5.83333C5.375 9.16667 5 9.54167 5 10C5 10.4583 5.375 10.8333 5.83333 10.8333Z" fill="#13A5A9"/>
    </svg>
  );
}


export default FilterIcon;