import React from 'react';


const Urgency = () => {

  return(
    <div className='flex items-center rounded px-2  bg-orange-500'>
      <p className='not-ilatic font-bold text-xs leading-4 text-white'>urgente</p>
    </div>
  );
}


export default Urgency;