import React from 'react';


const PreviousPage = () => {

  return (
    <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.81543" y="0.253906" width="24.5363" height="22.4916" rx="4.08939" fill="#27BEC2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.4206 7.55988C12.6202 7.7595 12.6202 8.08316 12.4206 8.28278L9.71498 10.9884H17.682C17.9643 10.9884 18.1932 11.2172 18.1932 11.4995C18.1932 11.7819 17.9643 12.0107 17.682 12.0107H9.71498L12.4206 14.7163C12.6202 14.9159 12.6202 15.2396 12.4206 15.4392C12.2209 15.6388 11.8973 15.6388 11.6977 15.4392L8.11945 11.861C7.91982 11.6614 7.91982 11.3377 8.11945 11.1381L11.6977 7.55988C11.8973 7.36025 12.2209 7.36025 12.4206 7.55988Z" fill="white"/>
    </svg>
  );
}


export default PreviousPage;